import React from 'react';
import styled, { css } from 'styled-components';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';
import { useTranslations } from 'use-intl';

import { convertRequestErrorToMap } from '@tager/web-core';
import { gtag } from '@tager/web-analytics';

import { media } from '@/utils/mixin';
import { validators } from '@/utils/validation';
import {
  CleaningAfterRepairCostRequestBody,
  requestCleaningAfterRepairCost,
} from '@/services/requests/requests';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { TextInputFormik } from '@/components/__old__/TextInput';
import { CheckboxFormik } from '@/components/__old__/Checkbox';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import Spinner, { Overlay } from '@/components/Spinner';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';
import { facebookPixelTrack } from '@/utils/analytics';
import {
  usePhoneMaskWithX,
  usePhoneMaskWithZero,
  usePhonePrefix,
} from '@/hooks/usePhonePrefix';
import { getBathroomsLabel, getRoomsLabel } from '@/i18n/formatters/services';
import { TFunction } from '@/i18n/i18n.types';

import Counter from './Counter';

type FormValues = CleaningAfterRepairCostRequestBody;

function validate(values: FormValues, t: TFunction) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.name)) {
    errors.name = t('form.name_error_required');
  }

  if (validators.required(values.phone)) {
    errors.phone = t('form.phone_error_required');
  }

  return errors;
}

type Props = {
  onSuccess: () => void;
};

function CalculatorFormContainer({ onSuccess }: Props) {
  const t = useTranslations();

  const { formAddress } = useRepairData();
  const phonePrefix = usePhonePrefix();

  function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) {
    facebookPixelTrack('Lead');
    gtag.event('repair:start');

    requestCleaningAfterRepairCost(
      { ...values, phone: phonePrefix + values.phone },
      formAddress
    )
      .then((response) => {
        if (response.success) {
          onSuccess();
        }
      })
      .catch((error) => {
        helpers.setErrors(convertRequestErrorToMap(error));
        helpers.setSubmitting(false);
      });
  }

  return (
    <Formik<FormValues>
      component={CalculatorForm}
      initialValues={{
        name: '',
        phone: '',
        rooms: 1,
        bathrooms: 1,
        isFurnitureCleaning: false,
        isWindows: false,
        withSteam: false,
        withVacuum: false,
      }}
      onSubmit={handleSubmit}
      validate={(values) => validate(values, t)}
      validateOnChange={false}
      validateOnBlur={false}
    />
  );
}

function CalculatorForm({ isSubmitting }: FormikProps<FormValues>) {
  const { formType } = useRepairData();
  const t = useTranslations();

  const phonePrefix = usePhonePrefix();
  const phoneMaskX = usePhoneMaskWithX();
  const phoneMaskZero = usePhoneMaskWithZero();

  return (
    <FormContainer data-testid="cost-request-form">
      {isSubmitting && (
        <Overlay>
          <Spinner color="yellow" />
        </Overlay>
      )}
      <Title>{t('form.apartment_description')}</Title>
      <NameInputContainer>
        <TextInputFormik
          name="name"
          placeholder={t('form.name')}
          clearErrorOnChange
          data-testid="name-field"
        />
      </NameInputContainer>
      <InputContainer>
        <MaskedTextInputFormik
          prefix={phonePrefix}
          name="phone"
          placeholder={phoneMaskX}
          mask={phoneMaskZero}
          clearErrorOnChange
          data-testid="phone-field"
          type="tel"
        />
      </InputContainer>

      <CalcBlockWrapper>
        <CounterRow>
          <Counter name="rooms" getLabel={getRoomsLabel} max={5} />
        </CounterRow>
        <CounterRow>
          <Counter name="bathrooms" getLabel={getBathroomsLabel} max={5} />
        </CounterRow>
      </CalcBlockWrapper>

      {formType === 'repair' && (
        <CheckboxContainer>
          <CheckboxFormik
            name="isFurnitureCleaning"
            label={t('form.furniture_cleaning')}
            data-testid="dry-cleaning-service-field"
          />
        </CheckboxContainer>
      )}

      <CheckboxContainer>
        <CheckboxFormik name="isWindows" label={t('form.windows_cleaning')} />
      </CheckboxContainer>

      {formType === 'deep' ? (
        <>
          <CheckboxContainer>
            <CheckboxFormik name="withVacuum" label={t('form.pylesos')} />
          </CheckboxContainer>

          <CheckboxContainer>
            <CheckboxFormik name="withSteam" label={t('form.par')} />
          </CheckboxContainer>
        </>
      ) : null}

      <FormButton type="submit" variant={'yellow'} disabled={isSubmitting}>
        {t('form.calculator_header_form_label')}
      </FormButton>
      <Agreement>
        {t('form.click_and_agree_for', {
          button_label: t('form.calculator_header_form_label'),
        })}{' '}
        <a href="/privacy-policy" target="_blank">
          {t('form.click_and_agree_for_personal')}
        </a>
      </Agreement>
    </FormContainer>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px -20px 0;
  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const FormContainer = styled(Form)`
  position: relative;
  padding: 30px 60px;
  background-color: ${colors.grayOpacity};
  color: ${colors.darkText};
  border-radius: 10px;
  overflow: hidden;

  ${media.laptop(css`
    padding: 25px 50px;
  `)}

  ${media.mobile(css`
    padding: 10px 25px;
  `)}
`;

const CalcBlockWrapper = styled.div`
  margin-bottom: 20px;
`;

const CounterRow = styled.div`
  margin-bottom: 20px;

  ${media.laptop(css`
    margin-bottom: 15px;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;

  ${media.laptop(css`
    font-size: 20px;
  `)}

  ${media.mobile(css`
    margin-bottom: 15px;
  `)}
`;

const FormButton = styled(Button)`
  font-size: 20px;
  padding: 22px 30px;
  width: 100%;
  font-weight: 600;
  color: ${colors.blueDark};

  ${media.laptop(css`
    font-size: 18px;
  `)}

  ${media.mobile(css`
    padding: 22px;
  `)}
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const NameInputContainer = styled(InputContainer)`
  input {
    text-align: center;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 20px;
  color: ${colors.blueDark};
`;

export default CalculatorFormContainer;
