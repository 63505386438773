import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';

import { Section, SectionTitle, TypeTitle } from '../Office.style';

type ItemProps = {
  title: {
    type: string;
    label: string;
  };
  description?: string;
};

function TypeOfCleaningItem({ title, description }: ItemProps) {
  const { type, label } = title;

  function getTitle() {
    return <ItemTitle style={{ background: type }}>{label}</ItemTitle>;
  }

  return (
    <ListItem>
      <TypeTitle>{getTitle()}</TypeTitle>
      {description ? <Description>{description}</Description> : null}
    </ListItem>
  );
}

function TypesOfCleaning() {
  const pageFields = useCurrentPage().templateFields;
  if (!pageFields) return null;

  const items = pageFields.typesItems.map((item: any) => {
    return {
      title: {
        type: item.color,
        label: item.title,
      },
      description: item.text,
    };
  });

  return (
    <Section>
      <ContentContainer>
        <SectionTitle>{pageFields.typesTitle}</SectionTitle>
        <List>
          {items.map((item: any, index: number) => (
            <TypeOfCleaningItem
              key={index}
              title={item.title}
              description={item.description}
            />
          ))}
        </List>
      </ContentContainer>
    </Section>
  );
}

const List = styled.ul``;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 75px;
  }

  ${media.laptop(css`
    &:not(:last-child) {
      margin-bottom: 55px;
    }
  `)}

  ${media.tablet(css`
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  `)}

  ${media.mobile(css`
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `)}
`;

const itemTitleCss = css`
  padding: 6px 10px;
  box-decoration-break: clone;

  ${media.tablet(css`
    padding: 6px;
  `)}

  ${media.mobile(css`
    padding: 5px;
  `)}
`;

const ItemTitle = styled.span`
  ${itemTitleCss};
`;

const Description = styled.p`
  margin: 0;
  font-size: 25px;
  line-height: 1.2;
  color: rgb(42, 42, 42);

  ${media.laptop(css`
    font-size: 22px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

export default TypesOfCleaning;
