import React from 'react';
import styled, { css } from 'styled-components';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';
import { useTranslations } from 'use-intl';

import { convertRequestErrorToMap } from '@tager/web-core';
import { gtag } from '@tager/web-analytics';

import { media } from '@/utils/mixin';
import { validators } from '@/utils/validation';
import {
  OfficeCleaningCostRequestBody,
  requestOfficeCleaningCost,
} from '@/services/requests/requests';
import { colors } from '@/constants/theme';
import Button from '@/components/__old__/Button';
import { CheckboxFormik } from '@/components/__old__/Checkbox';
import { TextInputFormik } from '@/components/__old__/TextInput';
import { MaskedTextInputFormik } from '@/components/__old__/MaskedTextInput';
import Spinner, { Overlay } from '@/components/Spinner';
import { facebookPixelTrack } from '@/utils/analytics';
import {
  usePhoneMaskWithX,
  usePhoneMaskWithZero,
  usePhonePrefix,
} from '@/hooks/usePhonePrefix';
import useCurrentPage from '@/hooks/useCurrentPage';

type FormValues = OfficeCleaningCostRequestBody;

function validate(values: FormValues) {
  const errors: FormikErrors<FormikValues> = {};

  if (validators.required(values.name)) {
    errors.name = 'Введите имя';
  }

  if (validators.required(values.phone)) {
    errors.phone = 'Введите номер телефона';
  }

  return errors;
}

type Props = {
  onSuccess: () => void;
};

function CalculatorFormContainer({ onSuccess }: Props) {
  const phonePrefix = usePhonePrefix();

  function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) {
    gtag.event('office:start');
    facebookPixelTrack('Lead');

    requestOfficeCleaningCost({
      ...values,
      phone: phonePrefix + values.phone,
    })
      .then((response) => {
        if (response.success) {
          onSuccess();
        }
      })
      .catch((error) => {
        helpers.setErrors(convertRequestErrorToMap(error));
        helpers.setSubmitting(false);
      });
  }

  return (
    <Formik<FormValues>
      component={CalculatorForm}
      initialValues={{
        name: '',
        phone: '',
        isWindows: false,
        isAfterRepair: false,
      }}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    />
  );
}

function CalculatorForm({ isSubmitting }: FormikProps<FormValues>) {
  const phonePrefix = usePhonePrefix();
  const phoneMaskX = usePhoneMaskWithX();
  const phoneMaskZero = usePhoneMaskWithZero();
  const t = useTranslations();

  const pageFields = useCurrentPage().templateFields;
  if (!pageFields) return null;

  return (
    <FormContainer data-testid="cost-request-form">
      {isSubmitting && (
        <Overlay>
          <Spinner color="blue" />
        </Overlay>
      )}
      <Title>{pageFields.heroFormTitle}</Title>
      <NameInputContainer>
        <TextInputFormik
          name="name"
          placeholder={t('form.name')}
          clearErrorOnChange
          data-testid="name-field"
        />
      </NameInputContainer>
      <InputContainer>
        <MaskedTextInputFormik
          prefix={phonePrefix}
          name="phone"
          placeholder={phoneMaskX}
          mask={phoneMaskZero}
          clearErrorOnChange
          data-testid="phone-field"
          type="tel"
        />
      </InputContainer>
      <CheckboxContainer>
        <CheckboxFormik
          name="isWindows"
          label={t('form.windows_cleaning')}
          data-testid="window-service-field"
        />
      </CheckboxContainer>
      <CheckboxContainer>
        <CheckboxFormik
          name="isAfterRepair"
          label={t('form.after_repair')}
          data-testid="repair-service-field"
        />
      </CheckboxContainer>

      <FormButton variant={'yellow'} type="submit" disabled={isSubmitting}>
        {pageFields.heroFormButtonLabel}
      </FormButton>

      <Agreement>
        {t('form.click_and_agree_for', {
          button_label: pageFields.heroFormButtonLabel,
        })}{' '}
        <a href="/privacy-policy" target="_blank">
          {t('form.click_and_agree_for_personal')}
        </a>
      </Agreement>
    </FormContainer>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px -20px 0;
  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const FormContainer = styled(Form)`
  position: relative;
  padding: 40px 70px;
  background-color: ${colors.grayOpacity};
  border-radius: 10px;
  overflow: hidden;

  ${media.laptop(css`
    padding: 30px 50px;
  `)}

  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const Title = styled.span`
  display: block;
  margin-bottom: 17px;
  font-size: 17px;
  line-height: 1.2;
  font-weight: bold;
  color: ${colors.blueDark};

  ${media.tabletSmall(css`
    margin-bottom: 10px;
    font-size: 14px;
  `)}
`;

const FormButton = styled(Button)`
  padding: 25px;
  font-size: 17px;
  color: ${colors.blueDark};
  font-weight: bold;
  width: 100%;

  ${media.tabletSmall(css`
    padding: 15px;
    font-size: 14px;
  `)}
`;

const Remark = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  color: ${colors.blueDark};

  ${media.tabletSmall(css`
    font-size: 10px;
  `)}
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const NameInputContainer = styled(InputContainer)`
  input {
    text-align: center;
  }
`;

const CheckboxContainer = styled.div`
  margin-bottom: 20px;
  color: ${colors.black};

  ${media.mobile(css`
    margin-bottom: 15px;
  `)}
`;

export default CalculatorFormContainer;
