import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { useRepairData } from '@/modules/Templates/Repair/Repair.hooks';

import { SectionTitle } from '../Repair.style';

function Equipment() {
  const { equipmentTitle, equipmentIcon, equipmentText, equipmentImage } =
    useRepairData();

  if (!equipmentTitle || !equipmentIcon || !equipmentText || !equipmentImage) {
    return null;
  }

  return (
    <Section>
      <ContentContainer>
        <SectionInner>
          <Left>
            <Title>
              {equipmentTitle.split('\n').map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  {equipmentTitle.length - 1 !== index && <br />}
                </React.Fragment>
              ))}
              <Icon src={equipmentIcon.url} />
            </Title>
            <Text dangerouslySetInnerHTML={{ __html: equipmentText ?? '' }} />
          </Left>
          <Right>
            {equipmentImage ? (
              <Picture
                src={equipmentImage.url}
                src2x={equipmentImage.url_2x}
                srcWebp={equipmentImage.url_webp}
                srcWebp2x={equipmentImage.url_webp_2x}
              />
            ) : null}
          </Right>
        </SectionInner>
      </ContentContainer>
    </Section>
  );
}

const Section = styled.section`
  padding: 90px 0 130px;

  ${media.tablet(css`
    padding: 80px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const SectionInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
    justify-content: stretch;
  `)}
`;

const Left = styled.div`
  width: 75%;
  margin-right: 50px;

  ${media.tabletSmall(css`
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  `)}
`;

const Right = styled.div`
  position: relative;
  flex: 1 1 auto;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-top: 93%;
  }

  ${media.tabletSmall(css`
    width: 100%;

    img {
      margin: 0 auto;
    }
  `)}
`;

const Title = styled(SectionTitle)`
  position: relative;
  padding-left: 100px;
  display: flex;

  ${media.mobile(css`
    padding-left: 90px;
  `)}
`;

const Icon = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  ${media.tabletSmall(css`
    top: 50%;
    transform: translateY(-50%);
  `)}
`;

const Text = styled.div`
  font-size: 22px;
  line-height: 1.2;
  font-weight: 300;
  color: ${colors.darkText};

  p:not(:last-child) {
    margin-bottom: 40px;
  }

  ${media.tablet(css`
    p:not(:last-child) {
      margin-bottom: 30px;
    }
  `)}

  ${media.mobile(css`
    p:not(:last-child) {
      margin-bottom: 25px;
    }
  `)}
`;

export default Equipment;
