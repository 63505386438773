import { getCurrentLocale } from '@/i18n/i18n.utils';

export function getRoomsLabel(count: number): string {
  if (getCurrentLocale() === 'en') {
    return count + ' ' + (count > 1 ? 'rooms' : 'room');
  }

  if (count % 10 == 1 && count % 100 !== 11) {
    return count + ' комната';
  }
  if (
    (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) &&
    count % 100 !== 12 &&
    count % 100 !== 13 &&
    count % 100 !== 14
  ) {
    return count + ' комнаты';
  }
  return count + ' комнат';
}

export function getBathroomsLabel(count: number): string {
  if (getCurrentLocale() === 'en') {
    return count + ' ' + (count > 1 ? 'bathrooms' : 'bathroom');
  }

  if (count % 10 == 1 && count % 100 !== 11) {
    return count + ' санузел';
  }
  if (
    (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) &&
    count % 100 !== 12 &&
    count % 100 !== 13 &&
    count % 100 !== 14
  ) {
    return count + ' санузела';
  }
  return count + ' санузелов';
}

export function getWindowsLabel(count: number): string {
  if (getCurrentLocale() === 'en') {
    return count + ' ' + (count > 1 ? 'windows' : 'window');
  }

  {
    if (count % 10 == 1 && count % 100 !== 11) {
      return count + ' окно';
    }
    if (
      (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) &&
      count % 100 !== 12 &&
      count % 100 !== 13 &&
      count % 100 !== 14
    ) {
      return count + ' окна';
    }
    return count + ' окон';
  }
}

export function getBalconiesLabel(count: number): string {
  if (getCurrentLocale() === 'en') {
    return count + ' ' + (count > 1 ? 'balconies' : 'balcony');
  }

  if (count === 0) {
    return 'Без балкона';
  }
  if (count % 10 == 1 && count % 100 !== 11) {
    return count + ' балкон';
  }
  if (
    (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) &&
    count % 100 !== 12 &&
    count % 100 !== 13 &&
    count % 100 !== 14
  ) {
    return count + ' балкона';
  }
  return count + ' балконов';
}
