import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

import { ItemIcon, ItemTitle } from '../AboutCleaning.style';
import { ROOM_ITEM_LIST_WITH_EXTRA } from '../AboutCleaning.constants';

type Props = {
  roomItem: {
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  };
};

export function RoomItem({
  roomItem,
  closeModal,
}: Props & { closeModal?: () => void }) {
  const { icon, title, content, link } = roomItem;
  const subItems = content.split('\n');

  return (
    <Item>
      {link ? (
        <Link as={ItemLink} to={link}>
          <ItemContent onClick={closeModal}>
            <ItemIcon>
              <Picture {...convertThumbnailToPictureProps(icon)} />
            </ItemIcon>
            <ItemTitle>{title}</ItemTitle>
          </ItemContent>
        </Link>
      ) : (
        <>
          <ItemIcon>
            <Picture {...convertThumbnailToPictureProps(icon)} />
          </ItemIcon>
          <ItemTitle>{title}</ItemTitle>
        </>
      )}

      <SubList>
        {subItems.map((subItem, index) => (
          <SubItem key={index}>{subItem}</SubItem>
        ))}
      </SubList>
    </Item>
  );
}

export function RoomItemWithExtraSubItem({
  roomItem,
  closeModal,
}: {
  closeModal?: () => void;
  roomItem: {
    title: string;
    icon: React.ReactNode;
    link?: string;
    subItems: Array<string | React.ReactNode>;
  };
}) {
  const { icon, title, subItems, link } = roomItem;
  return (
    <Item>
      {link ? (
        <Link as={ItemLink} to={link}>
          <ItemContent onClick={closeModal}>
            <ItemIcon>{icon}</ItemIcon>
            <ItemTitle>{title}</ItemTitle>
          </ItemContent>
        </Link>
      ) : (
        <>
          <ItemIcon>{icon}</ItemIcon>
          <ItemTitle>{title}</ItemTitle>
        </>
      )}

      <SubList>
        {subItems.map((subItem, index) => (
          <SubItem key={index}>{subItem}</SubItem>
        ))}
      </SubList>
    </Item>
  );
}

export function RoomsListWithExtraSubItem({
  closeModal,
}: {
  closeModal?: () => void;
}) {
  return (
    <List>
      {ROOM_ITEM_LIST_WITH_EXTRA.map((item, index) => (
        <RoomItemWithExtraSubItem
          closeModal={closeModal}
          key={index}
          roomItem={item}
        />
      ))}
    </List>
  );
}

function RoomsList({
  closeModal,
  servicesExtendedPrimary,
}: {
  closeModal: () => void;
  servicesExtendedPrimary: Array<{
    title: string;
    content: string;
    link: string;
    icon: { url: string };
  }>;
}) {
  return (
    <List>
      {servicesExtendedPrimary.map((item, index) => (
        <RoomItem closeModal={closeModal} key={index} roomItem={item} />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px;

  ${media.mobile(css`
    margin: -15px 0;
  `)}
`;

const Item = styled.li`
  width: calc(50% - 30px);
  margin: 30px 15px;

  ${media.mobile(css`
    width: 100%;
    margin: 15px 0;
    text-align: center;
  `)}
`;

const ItemLink = styled.a`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
`;

const ItemContent = styled.div`
  display: inline-block;
`;

const SubList = styled.ul`
  text-align: left;
`;

const SubItem = styled.li`
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: ${colors.blackText};
    border-radius: 100%;
  }

  ${media.mobile(css`
    font-size: 14px;
  `)}
  a {
    color: ${colors.green};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default RoomsList;
