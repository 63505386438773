import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import HowToOrder from '@/modules/Templates/SeoComponents/HowToOrder';
import CostSection from '@/modules/Templates/SeoComponents/CostSection';
import CompaniesReviews from '@/components/__old__/CompaniesReviews';
import WhatIncluded from '@/modules/Templates/SeoComponents/WhatIncluded';
import useCurrentPage from '@/hooks/useCurrentPage';

import QaSection from '../../../components/__old__/QaSection/QaSection';

import BestOfTheMonth from './components/BestOfTheMonth';
import BottomSection from './components/BottomSection';
import Hero from './components/Hero';

function Home() {
  const page = useCurrentPage();
  const templateFields = page.templateFields;

  if (!templateFields) return null;

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: true,
          isApartment: true,
        },
      }}
    >
      <Wrapper>
        <Hero templateFields={templateFields} />
        <HowToOrder
          howToTitle={templateFields.howToTitle}
          howToTabs={templateFields.howToTabs}
        />
        {templateFields?.servicesTabs && templateFields?.servicesTabs.length ? (
          <WhatIncluded />
        ) : null}
        <CostSection
          title={templateFields.costTitle}
          subtitle={templateFields.costText}
          costItems={templateFields.costItems}
          textBottom={templateFields.costTextBottom}
        />
        <BestOfTheMonth templateFields={templateFields} />
        {templateFields?.faqItems ? (
          <QaSection
            faqItems={templateFields.faqItems}
            faqTitle={templateFields.faqTitle}
          />
        ) : null}
        {/*<ReviewSection />*/}
        {/*templateFields ? (
          <FacebookSection
            facebookFrameUrl={templateFields.facebookFrameUrl}
            facebookButtonLabel={templateFields.facebookButtonLabel}
            facebookButtonLink={templateFields.facebookButtonLink}
          />
        ) : null}{' '}*/}
        <CompaniesReviews companyList={templateFields.press} />
        <BottomSection templateFields={templateFields} />
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div``;

export default Home;
