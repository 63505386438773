import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import CostRequestFormContainer from '@/modules/Templates/Windows/components/CostRequestForm';
import { colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import { ReactComponent as ScrollDownIcon } from '@/assets/svg/scrolldown.svg';

type Props = {
  templateFields: Record<string, any>;
};

function Hero({ templateFields }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const t = useTranslations();

  const background = templateFields.heroBackground;
  const badge = templateFields.heroBadge;
  const title = templateFields.heroTitle;
  const subTitle = templateFields.heroText;
  const formTitle = templateFields.heroFormTitle;
  const formBottomText = templateFields.heroFormBottom;

  const { headerSubmitLabel } = templateFields;

  function handleButtonClick() {
    if (!containerRef.current) return;

    const targetPosition =
      window.pageYOffset +
      containerRef.current.getBoundingClientRect().bottom -
      63;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  }

  return (
    <HeroContainer ref={containerRef}>
      {background ? (
        <Background>
          <Picture
            desktop={convertThumbnailToPictureProps(background.desktop)}
            laptop={convertThumbnailToPictureProps(background.laptop)}
          />
        </Background>
      ) : null}
      <ContentContainer>
        <HeroInner>
          <Intro>
            {badge ? <TitleTag>{badge}</TitleTag> : null}
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
            {subTitle ? <Subtitle>{subTitle}</Subtitle> : null}
          </Intro>
          <FormContainer>
            {formTitle ? <FormTitle>{formTitle}</FormTitle> : null}
            <CostRequestFormContainer
              isHero
              formSubmitLabel={headerSubmitLabel}
            />
            {formBottomText ? (
              <FormRemark>{t('order-form.balcony_description')}</FormRemark>
            ) : null}
            <Agreement>
              {t('form.click_and_agree_for', {
                button_label: t('form.calculator_header_form_label'),
              })}{' '}
              <a href="/privacy-policy" target="_blank">
                {t('form.click_and_agree_for_personal')}
              </a>
            </Agreement>
          </FormContainer>
        </HeroInner>
      </ContentContainer>
      <ScrollDownButton onClick={handleButtonClick}>
        <ScrollDownIcon />
      </ScrollDownButton>
    </HeroContainer>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px -30px 0;
  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    color: ${colors.white};
    font-size: 14px;
    line-height: 20px;

    color: ${colors.white};
    margin: 20px 0 0;
  `)}
  a {
    text-decoration: underline;
    ${media.tablet(css`
      color: ${colors.white};
    `)};

    &:hover {
      text-decoration: none;
    }
  }
`;

const HeroContainer = styled.div`
  position: relative;
  padding-top: 170px;
  padding-bottom: 70px;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    min-height: auto;
    padding-bottom: 80px;
    padding-top: 95px;
    background-color: ${colors.green};
    align-items: normal;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

const HeroInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tablet(css`
    justify-content: normal;
    align-items: center;
    flex-direction: column;
  `)}
`;

const FormContainer = styled.div`
  flex: 0 0 470px;
  margin-left: 50px;
  padding: 50px 85px;
  background-color: ${colors.white};
  border-radius: 10px;
  overflow: hidden;

  ${media.laptop(css`
    flex: 0 0 420px;
    padding: 50px;
  `)}

  ${media.tablet(css`
    flex: 0;
    max-width: 296px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  `)}
`;

const FormTitle = styled.span`
  display: block;
  text-align: center;
  margin: 0 -20px 25px;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: -0.7px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const FormRemark = styled.span`
  display: block;
  margin-top: 22px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #88919d;
  font-weight: 500;

  ${media.tablet(css`
    color: ${colors.white};
  `)}

  ${media.mobile(css`
    font-size: 14px;
  `)}
`;

const Intro = styled.div`
  max-width: 600px;

  ${media.tablet(css`
    max-width: 510px;
    margin: 0 auto 30px;
    text-align: center;
  `)}
`;

const TitleTag = styled.div`
  display: inline-flex;
  margin: 0 0 15px 6px;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  background: ${colors.yellowLight};
`;

const Title = styled.h1`
  margin: 0 0 50px;
  font-size: 72px;
  font-weight: 900;
  line-height: 75px;
  color: ${colors.blackText};
  letter-spacing: -1.7px;
  max-width: 470px;

  ${media.tablet(css`
    margin: 0 auto 40px;
    color: ${colors.white};
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 55px;
  `)}

  ${media.mobile(css`
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;

    span {
      display: block;
    }
  `)}
`;

const Subtitle = styled.p`
  font-size: 22px;
  line-height: 40px;
  color: ${colors.blackText};
  font-weight: 500;
  letter-spacing: -0.5px;

  ${media.tablet(css`
    font-size: 18px;
    line-height: 32px;
    color: ${colors.white};
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 24px;
    color: ${colors.white};
  `)}
`;

const ScrollDownButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  z-index: 10;
  border-radius: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: ${colors.white};
`;

export default Hero;
