import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { nl2br } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { googleAnalytics } from '@tager/web-analytics';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Messengers from '@/components/__old__/Messengers';
import Link from '@/components/Link';
import { ReactComponent as WorkBackground } from '@/assets/svg/footer/work-footer.svg';
import { ReactComponent as PhoneIcon } from '@/assets/svg/phone-icon.svg';
import { ReactComponent as LetterIcon } from '@/assets/svg/footer/letter.svg';
import { ReactComponent as VkIcon } from '@/assets/svg/footer/vk.svg';
import { ReactComponent as FbIcon } from '@/assets/svg/footer/fb.svg';
import { ReactComponent as InstaIcon } from '@/assets/svg/footer/insta.svg';
import { ReactComponent as TmIcon } from '@/assets/svg/messengers/telegram.svg';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { getPhoneNumber } from '@/utils/common';
import useSettingItem from '@/hooks/useSettingItem';
import Picture from '@/components/Picture';
import FooterMobileStores from '@/components/Layout/components/Footer/components/FooterMobileStores';
import { useCurrency } from '@/hooks/useCurrency';

import { LayoutConfig } from '../../Layout.types';

type Props = NonNullable<LayoutConfig['footer']>;

function Footer({ isOfficePageInfo }: Props) {
  const t = useTranslations();
  const footerMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const layoutWorkerMinSalary = useSettingItem('LAYOUT_WORKER_MIN_SALARY');
  const legalInfoCommon = useSettingItem('LAYOUT_LEGAL_INFO');
  const legalInfoOffice = useSettingItem('LAYOUT_OFFICES_LEGAL_INFO');

  const emailCommon = useSettingItem('LAYOUT_EMAIL');
  const emailOffice = useSettingItem('LAYOUT_OFFICES_EMAIL');

  const phoneCommon = useSettingItem('LAYOUT_PHONE');
  const phoneOffice = useSettingItem('LAYOUT_OFFICES_PHONE');

  const logoTextCommon = useSettingItem('LAYOUT_BELOW_LOGO_TEXT');
  const logoTextOffice = useSettingItem('LAYOUT_OFFICES_BELOW_LOGO_TEXT');
  const logoFooter = useSettingItem('LOGO_FOOTER');

  const legalInfo = isOfficePageInfo ? legalInfoOffice : legalInfoCommon;
  const email = isOfficePageInfo ? emailOffice : emailCommon;
  const phone = isOfficePageInfo ? phoneOffice : phoneCommon;
  const logoText = isOfficePageInfo ? logoTextOffice : logoTextCommon;

  const phoneNumber = getPhoneNumber(phone ?? '');

  const telegramUrl = useSettingItem('SOCIAL_TELEGRAM');
  const vkUrl = useSettingItem('SOCIAL_VKONTAKTE');
  const facebookUrl = useSettingItem('SOCIAL_FACEBOOK');
  const instagramUrl = useSettingItem('SOCIAL_INSTAGRAM');

  const careerEnabled = useSettingItem('LAYOUT_FOOTER_CAREER_BADGE_ENABLED');
  const careerLink = useSettingItem('LAYOUT_FOOTER_CAREER_BADGE_LINK');
  const careerText = useSettingItem('LAYOUT_FOOTER_CAREER_BADGE_TEXT');

  const currency = useCurrency();

  return (
    <FooterContainer>
      <ContentContainer>
        <FooterInner>
          <Left>
            <Link as={LogoContainer} to="/web/public">
              {logoFooter ? (
                <Picture {...convertThumbnailToPictureImage(logoFooter)} />
              ) : null}
              {logoText ? <LogoText>{logoText}</LogoText> : null}
            </Link>
            <LeftText>
              &copy; 2016-{new Date().getFullYear()}, GetClean
            </LeftText>
            <LeftStores>
              <FooterMobileStores />
            </LeftStores>
            {careerEnabled ? (
              <WorkLink to={careerLink}>
                <WorkBackground />
                <WorkContent>
                  {careerText && (
                    <WorkTop dangerouslySetInnerHTML={{ __html: careerText }} />
                  )}
                  <WorkMiddle>{t('layout.footer_work_middle')}</WorkMiddle>
                  <WorkBottom>
                    {layoutWorkerMinSalary}{' '}
                    <span>
                      {currency} {t('layout.footer_work_in_month')}
                    </span>
                  </WorkBottom>
                </WorkContent>
              </WorkLink>
            ) : null}
          </Left>
          <Middle>
            <Menu>
              <MenuRow>
                {footerMenuItemList.map((menuItem) => (
                  <MenuItem key={menuItem.id}>
                    <Link
                      as={MenuLink}
                      to={menuItem.link}
                      target={menuItem.isNewTab ? '_blank' : undefined}
                    >
                      {menuItem.label}
                    </Link>
                  </MenuItem>
                ))}
              </MenuRow>
            </Menu>
            <MiddleText>
              <TextParagraph>{nl2br(legalInfo)}</TextParagraph>
              <TextParagraph>
                {phone ? (
                  <>
                    {t('layout.footer_tel')}
                    <DescTelLink
                      href={'tel:' + phoneNumber}
                      onClick={() => {
                        googleAnalytics.trackEvent('event', 'phone');
                      }}
                    >
                      {phone}
                    </DescTelLink>{' '}
                  </>
                ) : null}
                <DescMailLink href={'mailto:' + email}>{email}</DescMailLink>
              </TextParagraph>
            </MiddleText>
          </Middle>
          <Right>
            <RightInfo>
              <RightInfoItem>
                {phone ? (
                  <TelLink
                    href={'tel:' + phoneNumber}
                    onClick={() => {
                      googleAnalytics.trackEvent('event', 'phone');
                    }}
                  >
                    <PhoneIcon />
                    {phone}
                  </TelLink>
                ) : null}
              </RightInfoItem>
              <RightInfoItem>
                {email ? (
                  <MailLink href={'mailto:' + email} target="_blank">
                    <LetterIcon />
                    {email}
                  </MailLink>
                ) : null}
              </RightInfoItem>
            </RightInfo>
            <RightSocial>
              {telegramUrl ? (
                <SocialItem>
                  <SocialLinkVk href={telegramUrl} target="_blank">
                    <TmIcon />
                    Telegram
                  </SocialLinkVk>
                </SocialItem>
              ) : null}

              {facebookUrl ? (
                <SocialItem>
                  <SocialLinkFb href={facebookUrl} target="_blank">
                    <FbIcon />
                    Facebook
                  </SocialLinkFb>
                </SocialItem>
              ) : null}

              {instagramUrl ? (
                <SocialItem>
                  <SocialLinkInsta href={instagramUrl} target="_blank">
                    <InstaIcon />
                    Instagram
                  </SocialLinkInsta>
                </SocialItem>
              ) : null}

              {vkUrl ? (
                <SocialItem>
                  <SocialLinkVk href={vkUrl} target="_blank">
                    <VkIcon />
                    VKontakte
                  </SocialLinkVk>
                </SocialItem>
              ) : null}
            </RightSocial>
          </Right>
          <LeftTextMobile>
            &copy; 2016-{new Date().getFullYear()}, GetClean
          </LeftTextMobile>
        </FooterInner>
      </ContentContainer>
      <Messengers />
    </FooterContainer>
  );
}

const LeftStores = styled.div`
  margin-top: 20px;

  ${media.mobile(css`
    margin-bottom: 30px;
    margin-top: 0;
  `)}
`;

const FooterContainer = styled.footer`
  padding: 60px 0 80px;
  font-size: 16px;
  line-height: 1.2;
  background-color: #2d3234;
  color: ${colors.white};

  ${media.laptop(css`
    padding: 60px 0 80px;
  `)}

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${media.tabletSmall(css`
    padding: 60px 0 180px;
  `)}

  ${media.mobile(css`
    padding: 30px 0 120px;
  `)}
`;

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-wrap: wrap;
    justify-content: stretch;
  `)}
`;

const Left = styled.div`
  position: relative;
  width: 200px;
  min-height: 300px;

  ${media.tablet(css`
    width: auto;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  `)}

  ${media.mobile(css`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0;
  `)}
`;

const LogoText = styled.span`
  display: block;
  margin-left: 3px;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  color: ${colors.white};
  margin-top: 5px;
`;

const WorkLink = styled(Link)`
  position: absolute;
  display: block;
  bottom: -74px;
  width: 123px;
  height: 131px;
  padding: 30px 10px 5px;
  z-index: 0;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: 0.2s ease;
  }

  &:hover {
    svg {
      fill: ${colors.yellowDark};
    }
  }

  ${media.tablet(css`
    bottom: -54px;
  `)}

  ${media.tabletSmall(css`
    bottom: -347px;
  `)}

  ${media.mobile(css`
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    width: auto;
    height: auto;
    bottom: -247px;
    font-size: 14px;
    color: #3d3d3d;
    border-radius: 19.5px;
    background-color: #ffe16c;

    svg {
      display: none;
    }

    &:hover {
      background-color: ${colors.yellowDark};
    }
  `)}
`;

const WorkContent = styled.div`
  z-index: 1;
  text-align: center;
  color: ${colors.black};
  line-height: 1.2;
`;

const WorkTop = styled.div`
  font-size: 16px;

  b {
    font-weight: 600;
  }
`;

const WorkMiddle = styled.span`
  font-size: 12px;

  ${media.mobile(css`
    display: none;
  `)}
`;

const WorkBottom = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 10px;
    display: inline-flex;
    width: 40%;
    margin-left: 5px;
  }

  ${media.mobile(css`
    display: none;
  `)}
`;

const LogoContainer = styled.a`
  display: block;
  margin-bottom: 20px;

  ${media.tablet(css`
    margin-bottom: 40px;
  `)}
  ${media.mobile(css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `)}
  img {
    max-width: 150px;
  }
`;

const LeftText = styled.span`
  font-size: 13px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const LeftTextMobile = styled.span`
  display: none;
  font-size: 13px;

  ${media.tabletSmall(css`
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  `)}
`;

const Middle = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0 30px;
  margin: 0 auto;
  font-size: 16px;

  ${media.tablet(css`
    font-size: 14px;
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

const Menu = styled.ul`
  margin-bottom: 50px;
`;

const MenuRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px -10px -5px -10px;
  max-width: 600px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const MenuItem = styled.li`
  margin: 5px 10px;
`;

const MenuLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const MiddleText = styled.div``;

const TextParagraph = styled.p`
  font-size: 16px;
  line-height: 1.7;
}

&:not(:last-child) {
  margin-bottom: 5px;
}

${media.laptop(css`
  font-size: 14px;
`)}
${media.tablet(css`
  font-size: 12px;
`)}
`;

const descLinkCss = css`
  display: inline-flex;
  margin: 0 3px;

  &:hover {
    text-decoration: underline;
  }
`;

const DescTelLink = styled.a`
  ${descLinkCss};
`;

const DescMailLink = styled.a`
  ${descLinkCss};
`;

const Right = styled.div`
  width: 200px;
  flex: 0 0 200px;
  text-align: right;

  ${media.tabletSmall(css`
    width: auto;
    flex: 1 1 100%;
    text-align: center;
  `)};
`;

const RightInfo = styled.ul`
  margin-bottom: 30px;
`;

const RightInfoItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const RightSocial = styled.ul`
  width: 145px;
  margin-left: auto;

  ${media.tabletSmall(css`
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  `)}
`;

const SocialItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${media.tabletSmall(css`
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 35px;
    }
  `)}

  ${media.mobile(css`
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  `)}
`;

const socialLinkCss = css`
  position: relative;
  display: block;
  padding: 12px 18px 12px 57px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 19.5px;

  svg {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${media.mobile(css`
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: 0;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `)}
`;

const SocialLinkVk = styled.a`
  ${socialLinkCss};
  color: ${colors.white};
  background-color: #3f82b2;

  &:hover {
    background-color: #38749f;
  }
`;

const SocialLinkFb = styled.a`
  ${socialLinkCss};
  color: ${colors.white};
  background-color: #345aab;

  &:hover {
    background-color: #2e5097;
  }
`;

const SocialLinkInsta = styled.a`
  ${socialLinkCss};
  color: #0d0d0d;
  background-color: #e4e4e4;

  &:hover {
    background-color: #d7d7d7;
  }
`;

const TelLink = styled.a`
  position: relative;
  padding-left: 20px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
  color: ${colors.yellow};

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: currentColor;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const MailLink = styled.a`
  position: relative;
  padding-left: 30px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: currentColor;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }

  ${media.tablet(css`
    font-size: 12px;
  `)}
`;

export default Footer;
