import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import Button from '@/components/__old__/Button';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';

import { Section, SectionTitle } from '../Office.style';

function BottomSection() {
  const templateFields = useCurrentPage().templateFields;

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (!templateFields) {
    return null;
  }

  return (
    <Section>
      <ContentContainer>
        <Title>{templateFields.ctaTitle}</Title>
        <CostButton
          onClick={handleButtonClick}
          variant={'yellow'}
          data-testid="scroll-top-button"
        >
          {templateFields.ctaButtonLabel}
        </CostButton>
      </ContentContainer>
    </Section>
  );
}

const Title = styled(SectionTitle)`
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
  text-transform: uppercase;
`;

const CostButton = styled(Button)`
  display: block;
  font-size: 17px;
  padding: 25px 50px;
  max-width: 344px;
  margin: 0 auto 8px;
  color: ${colors.blueDark};

  ${media.mobile(css`
    padding: 15px;
    font-size: 14px;
    margin-bottom: 6px;
  `)}
`;

export default BottomSection;
