import React, { useEffect, useRef, useState } from 'react';
import Imask from 'imask';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import {
  usePhoneMaskWithX,
  usePhoneMaskWithZero,
  usePhonePrefix,
} from '@/hooks/usePhonePrefix';

export type PhoneInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onChangeValue?: (value: string) => void;
};

function PhoneInput({
  value,
  label,
  onFocus,
  onBlur,
  disabled,
  error,
  onChange,
  onChangeValue,
  ...textInputProps
}: PhoneInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const imaskRef = useRef<Imask.InputMask<any> | null>();
  const mask = usePhoneMaskWithZero();
  const [isFocused, setFocused] = useState(false);

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (onFocus) {
      onFocus(event);
    }
    setFocused(true);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (onBlur) {
      onBlur(event);
    }
    setFocused(false);
  }

  useEffect(() => {
    if (!inputRef.current) return;

    if (!imaskRef.current) {
      imaskRef.current = Imask(inputRef.current, {
        mask,
        undefined,
      });
    }

    const imask = imaskRef.current;
    imask!.unmaskedValue = value as string;

    function acceptListener() {
      if (onChangeValue) {
        onChangeValue(imask.unmaskedValue);
      }
    }

    imaskRef.current.on('accept', acceptListener);

    return () => {
      imask.off('accept', acceptListener);
    };
  }, [mask, onChange]);

  const phonePrefix = usePhonePrefix();
  const phoneMask = usePhoneMaskWithX();

  return (
    <Container>
      <Label>{label}</Label>
      <Prefix error={!!error} focused={isFocused}>
        {phonePrefix}
      </Prefix>
      <Input
        ref={inputRef}
        disabled={disabled}
        error={!!error}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={phoneMask}
        {...textInputProps}
      />
    </Container>
  );
}

export default PhoneInput;

const Container = styled.div`
  position: relative;
`;

const Input = styled.input<{
  error?: boolean;
}>`
  width: 100%;
  height: 50px;
  border: 1px solid ${colors.border};
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  padding-right: 15px;
  padding-left: 75px;

  color: ${colors.black};

  &::placeholder {
    color: #b3b3bb;
  }

  &:focus {
    border-color: ${colors.green};
  }

  &:disabled {
    opacity: 0.9;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.red};
      &:focus {
        border-color: ${colors.red};
      }
    `}
`;

const Prefix = styled.span<{ error: boolean; focused: boolean }>`
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  background-color: ${(props) =>
    props.error ? colors.red : props.focused ? colors.green : colors.border};
  color: ${(props) =>
    props.error || props.focused ? colors.white : colors.blackText};
  font-size: 14px;
  ${media.tablet(css`
    border-radius: 3px 0 0 3px;
  `)}
`;

const Label = styled.label`
  font-size: 13px;
  color: #3d3d3d;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  white-space: nowrap;
`;
