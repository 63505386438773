import React, { useState } from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import OrderSuccess from '@/modules/Templates/Repair/components/Order/Order';
import useCurrentPage from '@/hooks/useCurrentPage';

import Hero from './components/Hero';
import ServicesSection from './components/ServicesSection';
import TypesOfCleaning from './components/TypesOfCleaning';
import HowToStart from './components/HowToStart';
import Advantages from './components/Advantages';
import BottomSection from './components/BottomSection';

function Office() {
  const page = useCurrentPage();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'blue',
          logoText: '',
          isMainBg: false,
          isOfficePageInfo: true,
        },
        footer: { isOfficePageInfo: false },
      }}
    >
      {isSuccess ? (
        <OrderSuccess />
      ) : (
        <Wrapper>
          <Hero
            image={page?.templateFields?.heroImage}
            title={page?.templateFields?.heroTitle}
            onSuccess={() => setIsSuccess(true)}
          />
          <ServicesSection />
          <TypesOfCleaning />
          <HowToStart />
          <Advantages />
          <BottomSection />
        </Wrapper>
      )}
    </Layout>
  );
}

const Wrapper = styled.div``;

export default Office;
