import React, { useState } from 'react';
import { useTranslations } from 'use-intl';

import { validators } from '@/utils/validation';
import { authPhoneSignup } from '@/services/requests/requests';
import TextInput from '@/components/TextInput';

import * as S from '../AuthModal.style';

type Props = {
  session: string;
  code: string;
  setLoading: (value: boolean) => void;
  onSubmit: (token: string) => void;
};

function LoginFormSignupView({ session, code, setLoading, onSubmit }: Props) {
  const t = useTranslations();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const onStartClick = async () => {
    setLoading(true);

    try {
      const response = await authPhoneSignup(session, code, name, email);
      onSubmit(response.data.token);
    } catch (e) {
      setLoading(false);
      alert(t('auth.invalid_data'));
    }
  };

  const validateForm = () => {
    return validators.email(email) && validators.required(name);
  };

  return (
    <>
      <S.InputRow>
        <TextInput
          label={t('auth.name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </S.InputRow>
      <S.InputRow>
        <TextInput
          label={t('auth.email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </S.InputRow>
      <S.SignInButtonRow>
        <S.SignInButton
          variant="green"
          type="submit"
          fullWidth={true}
          disabled={validateForm()}
          onClick={onStartClick}
        >
          {t('auth.create')}
        </S.SignInButton>
      </S.SignInButtonRow>
    </>
  );
}

export default LoginFormSignupView;
