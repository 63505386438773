import useSettingItem from '@/hooks/useSettingItem';

export function usePhonePrefix(): string {
  return useSettingItem('PHONE_PREFIX') || '+375';
}

export function usePhoneMaskWithX(): string {
  return useSettingItem('PHONE_FORMAT') || '(XX) XXX-XX-XX';
}

export function usePhoneMaskWithZero(): string {
  return usePhoneMaskWithX().replaceAll('X', '0');
}
