import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'use-intl';

import { api } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import Spinner, { Overlay } from '@/components/Spinner';
import {
  getUserProfileThunk,
  selectPreorderData,
  setUserAuthorizedThunk,
} from '@/store/reducers/auth';
import { setOrderStartRequest } from '@/services/requests/orders';
import { selectActiveCity } from '@/store/reducers/cities';

import * as S from '../AuthModal.style';

import LoginFormPhoneView from './LoginFormPhoneView';
import LoginFormCodeView from './LoginFormCodeView';
import LoginFormSignupView from './LoginFormSignupView';

type Props = {
  closeModal: () => void;
  phone?: string;
};

type Step = 'phone' | 'code' | 'signup';

function LoginFormContainer(props: Props) {
  const t = useTranslations();

  const router = useRouter();
  const dispatch = useDispatch();

  const [initialCode, setInitialCode] = useState<string>('');

  const [step, setStep] = useState<Step>('phone');
  const [phone, setPhone] = useState<string>(props.phone || '');
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [session, setSession] = useState<string>('');

  const preorderData = useTypedSelector((state) => selectPreorderData(state));

  const processToken = async (token: string) => {
    api.setAccessToken(token);

    await dispatch(getUserProfileThunk());
    dispatch(setUserAuthorizedThunk());

    if (preorderData) {
      const searchParams = new URLSearchParams();
      if (preorderData.rooms) {
        searchParams.set('rooms', String(preorderData.rooms));
        searchParams.set('bathrooms', String(preorderData.bathrooms));
      } else if (preorderData.windows) {
        searchParams.set('windows', String(preorderData.windows));
        searchParams.set('balconies', String(preorderData.balconies));
      }
      router
        .push({ pathname: '/order', search: searchParams.toString() })
        .then(() => props.closeModal());
    } else {
      router
        .push({ pathname: '/profile/orders' })
        .then(() => props.closeModal());
    }
  };

  let stepComponent = null;
  if (step === 'phone') {
    stepComponent = (
      <LoginFormPhoneView
        setLoading={setLoading}
        initialPhone={props.phone}
        onSubmit={(phone, session, code) => {
          setSession(session);
          setPhone(phone);
          setInitialCode(code);
          setStep('code');
        }}
      />
    );
  } else if (step === 'code') {
    stepComponent = (
      <LoginFormCodeView
        session={session}
        setLoading={setLoading}
        initialCode={initialCode}
        onSubmit={(code, token) => {
          setCode(code);
          if (token) {
            processToken(token);
          } else {
            setStep('signup');
          }
        }}
      />
    );
  } else if (step === 'signup') {
    stepComponent = (
      <LoginFormSignupView
        session={session}
        code={code}
        setLoading={setLoading}
        onSubmit={(token) => {
          processToken(token);
        }}
      />
    );
  }

  return (
    <>
      {loading ? (
        <Overlay>
          <Spinner color="green" />
        </Overlay>
      ) : null}
      <S.Title>{t('auth.customer_cabinet')}</S.Title>
      {stepComponent}
    </>
  );
}

export default LoginFormContainer;
