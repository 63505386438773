import React from 'react';
import styled from 'styled-components';

import Layout from '@/components/Layout';
import DefaultHero from '@/components/__old__/DefaultHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import StaticBlock from '@/components/StaticBlock';

function ServiceList() {
  const page = useCurrentPage();

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
        },
      }}
    >
      <DefaultHero title={page?.title} image={page?.image} />
      <SectionDefault>
        <ContentContainer>
          <StaticBlock content={page?.body} />
        </ContentContainer>
      </SectionDefault>
    </Layout>
  );
}

const SectionDefault = styled.section`
  padding: 60px 0;
`;

export default ServiceList;
