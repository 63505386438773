import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';

import { PlusesCardProps } from './PlusesCard.types';

function PlusesCard({ image, description }: PlusesCardProps) {
  return (
    <Component>
      {image ? (
        <Image
          src={image.url}
          src2x={image.url_2x}
          srcWebp={image.url_webp}
          srcWebp2x={image.url_webp_2x}
        />
      ) : null}
      <Text>{description}</Text>
    </Component>
  );
}

export default PlusesCard;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  border-radius: 16px;
  border: solid 1px rgba(204, 204, 204, 0.3);
`;

const Image = styled(Picture)`
  img {
    min-height: 80px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  margin-top: 33px;
`;
