import React from 'react';
import { useTranslations } from 'use-intl';

import Layout from '@/components/Layout';
import QaSection from '@/components/__old__/QaSection';
import CostSection from '@/modules/Templates/SeoComponents/CostSection';
import useCurrentPage from '@/hooks/useCurrentPage';
import IncludedInService from '@/modules/Templates/Windows/components/IncludedInService';

import Hero from './components/Hero';
import BottomSection from './components/BottomSection';
import HowItWorks from './components/HowItWorks/HowItWorks';
function Windows() {
  const pageData = useCurrentPage();
  const templateFields = pageData?.templateFields;

  const t = useTranslations();

  if (!templateFields) return null;

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
          logoText: t('order-form.apartments_cleaning_title'),
          isApartment: true,
        },
      }}
    >
      <Hero templateFields={templateFields} />
      {/*<FacebookSection
        facebookFrameUrl={templateFields.facebookFrameUrl}
        facebookButtonLabel={templateFields.facebookButtonLabel}
        facebookButtonLink={templateFields.facebookButtonLink}
      />*/}
      <CostSection
        title={templateFields.costTitle}
        costItems={templateFields.costItems}
      />
      <HowItWorks templateFields={templateFields} />
      {templateFields?.servicesTabs ? (
        <IncludedInService templateFields={templateFields} />
      ) : null}
      {templateFields?.faqItems ? (
        <QaSection
          faqItems={templateFields.faqItems}
          faqTitle={templateFields.faqTitle}
        />
      ) : null}
      <BottomSection templateFields={templateFields} />
    </Layout>
  );
}

export default Windows;
