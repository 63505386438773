import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import { Nullish } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import ExtraServiceList from '@/modules/Templates/SeoComponents/AboutCleaning/components/ExtraServiceList';

type Props = {
  servicesSecondaryTitle: Nullish<string>;
  servicesExtendedSecondaryItems: Nullish<
    Array<{
      title: string;
      content: string;
      link: string;
      icon: { url: string };
    }>
  >;
  closeModal: () => void;
};

function ExtraService({
  closeModal,
  servicesSecondaryTitle,
  servicesExtendedSecondaryItems,
}: Props) {
  const t = useTranslations();
  return (
    <Container>
      {servicesSecondaryTitle ? (
        <Title>{t('order-form.additional_title')}</Title>
      ) : null}
      <Wrapper>
        {servicesExtendedSecondaryItems ? (
          <ExtraServiceList
            servicesExtendedSecondaryItems={servicesExtendedSecondaryItems}
            closeModal={closeModal}
          />
        ) : null}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.span`
  display: block;
  margin-bottom: 60px;
  padding: 0 15px;
  font-size: 23px;
  line-height: 32px;
  font-weight: 500;

  ${media.mobile(css`
    margin-bottom: 40px;
    padding: 0 20px;
  `)}
`;

const Wrapper = styled.div`
  padding: 60px 15px;
  background-color: ${colors.grayBg};

  ${media.mobile(css`
    padding: 40px 20px;
  `)}
`;

export default ExtraService;
