import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as WindowIcon } from '@/assets/svg/modal/window.svg';
import { ReactComponent as StoveIcon } from '@/assets/svg/modal/stove.svg';
import { ReactComponent as CupboardIcon } from '@/assets/svg/modal/cupboard.svg';
import { ReactComponent as MicrowaveIcon } from '@/assets/svg/modal/microwave.svg';
import { ReactComponent as BalconyIcon } from '@/assets/svg/modal/balcony.svg';
import { ReactComponent as DishesIcon } from '@/assets/svg/modal/dishes.svg';
import { ReactComponent as IroningBoardIcon } from '@/assets/svg/modal/ironing-board.svg';
import { ReactComponent as FridgeIcon } from '@/assets/svg/modal/fridge.svg';
import { ReactComponent as KeysIcon } from '@/assets/svg/modal/keys.svg';
import { ReactComponent as ApartmentIcon } from '@/assets/svg/appartment.svg';
import { ReactComponent as BathIcon } from '@/assets/svg/bath.svg';
import { ReactComponent as KitchenIcon } from '@/assets/svg/kitchen.svg';
import { ReactComponent as CorridorIcon } from '@/assets/svg/corridor.svg';

export const EXCLUDED_ITEM_LIST =
  'Не поднимаем и не двигаем тяжелые предметы и мебель\n ' +
  'Не удаляем глубокие пятна и не чистим паром \n' +
  'Не делаем химическую чистку ковров \n ' +
  'Не применяем биологически опасные материалы \n ' +
  'Не чистим жалюзи и шторы \n ' +
  'Не делаем уборку в труднодоступных местах \n ' +
  'Не прибираем в кладовке \n ' +
  'Не моем стены';

const EXTRA_SERVICE_ITEM_1 = {
  icon: <WindowIcon />,
  title: 'Помоем окна',
  link: '/uslugi/moem-okna',
  text: 'Очистим с внутренней стороны, а в тёплое время года ещё и с наружной',
};

const EXTRA_SERVICE_ITEM_2 = {
  icon: <StoveIcon />,
  title: 'Помоем духовку',
  link: '/lifehack/otmit-duhovku',
  text: 'Удалим жир и загрязнения изнутри, очистим и протрём духовой шкаф',
};

const EXTRA_SERVICE_ITEM_3 = {
  icon: <CupboardIcon />,
  title: 'Приберем внутри кухонных шкафчиков',
  link: '/lifehack/shkafi',
  text: 'Освободим полки, протрём все поверхности изнутри и выбросим продукты, которые вам не нужны',
};

const EXTRA_SERVICE_ITEM_4 = {
  icon: <MicrowaveIcon />,
  title: 'Помоем микроволновку',
  link: '/lifehack/otmit-mikrovolnovku',
  text: 'Удалим жир и грязь изнутри, очистим поддон, протрём микроволновую печь',
};

const EXTRA_SERVICE_ITEM_5 = {
  icon: <BalconyIcon />,
  title: 'Приберем на балконе',
  text: 'Протрём все доступные поверхности, кроме остекления, и помоем пол',
};

const EXTRA_SERVICE_ITEM_6 = {
  icon: <DishesIcon />,
  title: 'Помоем посуду',
  text: 'Просто оставьте посуду в раковине',
};

const EXTRA_SERVICE_ITEM_7 = {
  icon: <IroningBoardIcon />,
  title: 'Погладим вещи',
  text: 'Тщательно выгладим одежду и бельё, если вы оставите их на гладильной доске',
};

const EXTRA_SERVICE_ITEM_8 = {
  icon: <FridgeIcon />,
  title: 'Почистим холодильник',
  link: '/lifehack/kholodilnik',
  text: 'Освободим полки, удалим загрязнения и выбросим мусор, только заранее отключите морозилку',
};

const EXTRA_SERVICE_ITEM_9 = {
  icon: <KeysIcon />,
  title: 'Доставим ключи',
  text: 'После завершения уборки привезем ключи туда, куда Вам будет удобно',
};

export const EXTRA_SERVICE_ITEM_LIST = [
  EXTRA_SERVICE_ITEM_1,
  EXTRA_SERVICE_ITEM_2,
  EXTRA_SERVICE_ITEM_3,
  EXTRA_SERVICE_ITEM_4,
  EXTRA_SERVICE_ITEM_5,
  EXTRA_SERVICE_ITEM_6,
  EXTRA_SERVICE_ITEM_7,
  EXTRA_SERVICE_ITEM_8,
  EXTRA_SERVICE_ITEM_9,
];

export const ITEM_LINK_LIST = [
  {
    label: 'Уборка квартир в Минске',
    link: '/uslugi/uborka-kvartir',
  },
  {
    label: 'Уборка комнат',
    link: '/uslugi/uborka-v-komnate',
  },
  {
    label: 'Уборка ванной комнаты и туалета',
    link: '/uslugi/uborka-vannoy-komnaty-tualeta',
  },
  {
    label: 'Уборка и чистка кухни',
    link: '/uslugi/uborka-kuhni',
  },
  {
    label: 'Подписка на поддерживающую уборку',
    link: '/uslugi/podpiska',
  },
  {
    label: 'Уборка после ремонта',
    link: '/uslugi/uborka-remont',
  },
  {
    label: 'Уборка после строительных работ',
    link: '/uslugi/stroitelnaya-uborka',
  },
  {
    label: 'Мытье окон',
    link: '/uslugi/moem-okna',
  },
  {
    label: 'Чистка радиаторов отопления',
    link: '/uslugi/moika-batarey',
  },
  {
    label: 'Уборка после пожара или затопления',
    link: '/uslugi/uborka-posle-pozhara-potopa',
  },
  {
    label: 'Уборка строительного мусора',
    link: '/uslugi/uborka-stroitelnogo-musora',
  },
];

const SubItemLink = styled.a``;

const ROOM_ITEM_1_EXTRA_SUBITEM = {
  icon: <ApartmentIcon />,
  title: 'В жилых комнатах',
  link: '/uslugi/uborka-v-komnate',
  subItems: [
    <>
      Делаем{' '}
      <Link as={SubItemLink} to={'/uslugi/generalnaya-uborka'}>
        генеральную уборку
      </Link>{' '}
      в{' '}
      <Link as={SubItemLink} to={'/uslugi/uborka-chastnykh-domov'}>
        загородных домах
      </Link>{' '}
      и{' '}
      <Link as={SubItemLink} to={'/uslugi/uborka-kvartir'}>
        городских квартирах.
      </Link>
    </>,
    'Складываем одежду и расставляем вещи',
    'Заправляем постель или меняем постельное белье',
    'Протираем все доступные поверхности, выключатели, двери и дверные ручки',
    'Протираем бытовую технику',
    'Чистим зеркала и стеклянные поверхности',
    'Пылесосим ковры, моем пол и протираем плинтусы',
  ],
};

const ROOM_ITEM_2 = {
  icon: <BathIcon />,
  title: 'В ванной и туалете',
  link: '/uslugi/uborka-vannoy-komnaty-tualeta',
  subItems: [
    'Протираем все доступные поверхности, выключатели, двери и дверные ручки',
    'Моем сантехнику, душевую кабинку и ванную',
    'Чистим зеркала и стеклянные поверхности',
    'Чистим раковину и дезинфицируем унитаз',
    'Пылесосим коврики, моем пол и протираем плинтусы',
    'Выносим мусор',
  ],
};

const ROOM_ITEM_3 = {
  icon: <KitchenIcon />,
  title: 'На кухне',
  link: '/uslugi/uborka-kuhni',
  subItems: [
    'Моем посуду, чистим раковину и сантехнику',
    'Протираем все доступные поверхности, выключатели, двери и дверные ручки',
    'Моем снаружи плиту, столешницу, кухонный фартук и холодильник',
    'Протираем бытовую технику',
    'Протираем фасады кухонных шкафчиков',
    'Пылесосим и моем пол, протираем плинтусы',
    'Выносим мусор',
  ],
};

const ROOM_ITEM_4 = {
  icon: <CorridorIcon />,
  title: 'В коридоре',
  subItems: [
    'Протираем все доступные поверхности, выключатели, входную дверь и дверные ручки',
    'Чистим зеркала и стеклянные поверхности',
    'Пылесосим ковры, моем пол и протираем плинтусы',
    'Протираем бытовую технику',
    'Поправляем обувь',
  ],
};

export const ROOM_ITEM_LIST_WITH_EXTRA = [
  ROOM_ITEM_1_EXTRA_SUBITEM,
  ROOM_ITEM_2,
  ROOM_ITEM_3,
  ROOM_ITEM_4,
];

export const LIFE_HACK_LIST = [
  {
    label: 'Как отмыть вытяжку',
    link: '/lifehack/vytyazhka',
  },
  {
    label: 'Отмыть варочную поверхность',
    link: '/lifehack/varochnaya-poverhnost',
  },
  {
    label: 'Как отмыть плиту от жира и нагара',
    link: '/lifehack/plita',
  },
  {
    label: 'Чистка жалюзи',
    link: '/lifehack/zhaluzi',
  },
];
