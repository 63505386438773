import React from 'react';
import styled, { css } from 'styled-components';

import useSliderMedia from '@/hooks/useSliderMedia';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { DEFAULT_SLIDER_CONFIG } from '@/constants/common';
import useCurrentPage from '@/hooks/useCurrentPage';

import { Section, SectionTitle } from '../Office.style';

import ServicesItem from './ServicesItem';

function ServicesSection() {
  const ref = useSliderMedia('(max-width: 1260px)', DEFAULT_SLIDER_CONFIG);
  const pageFields = useCurrentPage().templateFields;
  if (!pageFields) return null;

  const serviceItemsList = pageFields.servicesSections.map((item: any) => {
    const textItems = item.items.split('\n');

    const linkComponents = textItems.map((label: string) => {
      return { label, link: item.link };
    });

    return {
      title: item.title,
      text: item.text,
      linkComponents,
    };
  });

  return (
    <SectionServices>
      <ContentContainer>
        <SectionTitle>{pageFields.servicesTitle}</SectionTitle>
        <SliderContainer className="swiper-container" ref={ref}>
          <ServicesList className="swiper-wrapper">
            {serviceItemsList.map((serviceItem: any, index: number) => (
              <Item key={index} className="swiper-slide">
                <ServicesItem serviceItem={serviceItem} />
              </Item>
            ))}
          </ServicesList>
          <SliderPagination className="swiper-pagination" />
        </SliderContainer>
      </ContentContainer>
    </SectionServices>
  );
}

const SectionServices = styled(Section)`
  padding-top: 100px;
  overflow: hidden;

  ${media.laptop(css`
    padding-top: 80px;
  `)}

  ${media.tablet(css`
    padding-top: 60px;
  `)}

  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

const SliderContainer = styled.div`
  overflow: visible;
`;

const ServicesList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Item = styled.li`
  width: 375px;
  display: flex;
  height: auto;

  ${media.mobile(
    css`
      width: 320px;
    `
  )}
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

const SliderPagination = styled.div<{ isHidden?: boolean }>`
  position: relative;
  margin: 30px auto 0;
  bottom: 0 !important;
  left: auto !important;
  width: auto !important;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;

    &-active {
      background-color: ${colors.blue};
    }
  }

  ${media.laptopUp(css`
    display: none;
  `)}
`;

export default ServicesSection;
