import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslations } from 'use-intl';

import MainMenu from '@/components/Layout/components/MainMenu';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { LayoutConfig } from '@/components/Layout/Layout.types';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

type Props = LayoutConfig['header'];

function HeaderMenu({ colorVariant, isHideHeaderMenu }: Props) {
  const t = useTranslations();

  const headerMenuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];

  return headerMenuItemList.length ? (
    <HeaderMenuContent
      isHideHeaderMenu={isHideHeaderMenu ? isHideHeaderMenu : false}
    >
      <ContentContainer>
        <Inner>
          <MainMenu menuList={headerMenuItemList} colorVariant={colorVariant} />
          <CleanerBadge to="/career">
            {t('layout.header_become_cleaner')}
          </CleanerBadge>
        </Inner>
      </ContentContainer>
    </HeaderMenuContent>
  ) : null;
}

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderMenuContent = styled.div<{ isHideHeaderMenu: boolean }>`
  display: ${(props) => (props.isHideHeaderMenu ? 'none' : 'block')};
  padding: 15px 0 15px 0;
  margin-bottom: 25px;
  position: relative;
  top: 62px;
  z-index: 2;
  overflow: hidden;
  background: #fff;

  ${media.tablet(css`
    padding: 26px 0 18px;
    margin-bottom: 0;
  `)}
`;

const CleanerBadge = styled(Link)`
  margin-left: auto;
  background: ${colors.yellow};
  font-weight: bold;
  border-radius: 10px;
  padding: 10px 30px;
  transition: 0.3s all ease;

  &:hover {
    background: ${colors.yellowDark};
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

export default HeaderMenu;
