import React from 'react';
import styled from 'styled-components';
import { useTranslations } from 'use-intl';

import Layout from '@/components/Layout';
import DefaultHero from '@/components/__old__/DefaultHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import ContentContainer from '@/components/ContentContainer';
import StaticBlock from '@/components/StaticBlock';
import HowToOrder from '@/modules/Templates/SeoComponents/HowToOrder';
import WhatIncluded from '@/modules/Templates/SeoComponents/WhatIncluded';
import { HOWTO_ORDER_ITEMS } from '@/modules/Templates/SeoComponents/HowToOrder.helpers';
import LandingCalculateForm from '@/components/LandingCalculateForm';

function ServiceTabsHelpContent() {
  const page = useCurrentPage();

  const t = useTranslations();

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'green',
          isMainBg: false,
        },
      }}
    >
      <DefaultHero title={page?.title} image={page?.image} />

      <WhatIncluded />

      <HowToOrder
        howToTitle={t('content.very_want_to_order')}
        howToTabs={HOWTO_ORDER_ITEMS}
      />

      <LandingCalculateForm />

      <SectionDefault>
        <ContentContainer>
          <StaticBlock content={page?.body} />
        </ContentContainer>
      </SectionDefault>
    </Layout>
  );
}

const SectionDefault = styled.section`
  padding-bottom: 60px;
`;

export default ServiceTabsHelpContent;
