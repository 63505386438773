import React from 'react';
import styled, { css } from 'styled-components';
import {useTranslations} from "use-intl";

import CalculatorForm from '@/modules/Templates/Home/components/CalculatorFormBottomSection';
import { media } from '@/utils/mixin';

function LandingCalculateForm() {
  const t = useTranslations();
  return (
    <FormWrapper>
      <FormContainer>
        <CalculatorForm submitLabel={t('form.calculator_header_form_label')} />
      </FormContainer>
    </FormWrapper>
  );
}

const FormContainer = styled.div`
  width: 296px;

  ${media.tabletSmall(css`
    margin: 0 auto;
  `)}
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 80px;
`;

export default LandingCalculateForm;
