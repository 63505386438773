import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslations } from 'use-intl';

import CompaniesReviews from '@/components/__old__/CompaniesReviews';
import Layout from '@/components/Layout';
import Order from '@/modules/Templates/Repair/components/Order';
import useCurrentPage from '@/hooks/useCurrentPage';

import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import WhatIsIncluded from './components/WhatIsIncluded';
import Equipment from './components/Equipment';
import OrderSection from './components/OrderSection';

function Repair() {
  const templateFields = useCurrentPage().templateFields;

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const t = useTranslations();

  if (!templateFields) return null;

  return (
    <Layout
      config={{
        header: {
          colorVariant: 'yellow',
          logoText: t('form.repair_title'),
          isRepairPageInfo: true,
          isMainBg: false,
        },
      }}
    >
      {isSuccess ? (
        <Order />
      ) : (
        <Wrapper>
          <Hero onSuccess={() => setIsSuccess(true)} />
          <HowItWorks />
          <WhatIsIncluded />
          <Equipment />
          <CompaniesReviews companyList={templateFields.press} />
          <OrderSection />
        </Wrapper>
      )}
    </Layout>
  );
}

const Wrapper = styled.div``;

export default Repair;
