import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {useTranslations} from "use-intl";

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { ReactComponent as ScrollDownIcon } from '@/assets/svg/scrolldown.svg';
import { processText } from '@/utils/regions';
import { useTypedSelector } from '@/store/store';
import { selectActiveCity, selectCities } from '@/store/reducers/cities';
import Link from '@/components/Link';

import CalculatorForm from './CalculatorForm';

type Props = {
  templateFields: Record<string, any>;
};

function Hero({ templateFields }: Props) {
  const t = useTranslations();

  const containerRef = useRef<HTMLDivElement>(null);
  const activeCity = useTypedSelector(selectActiveCity);

  function handleButtonClick() {
    if (!containerRef.current) return;

    const targetPosition =
      window.pageYOffset +
      containerRef.current.getBoundingClientRect().bottom -
      63;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  }

  return (
    <HeroContainer ref={containerRef}>
      <Background>
        {templateFields.background ? (
          <DesktopBg>
            <Picture
              desktop={convertThumbnailToPictureProps(
                templateFields.background.desktop
              )}
              laptop={convertThumbnailToPictureProps(
                templateFields.background.laptop
              )}
            />
          </DesktopBg>
        ) : null}
        {templateFields.mobileBackground ? (
          <MobileBg>
            <Shadow />
            <Picture
              {...convertThumbnailToPictureProps(
                templateFields.mobileBackground
              )}
            />
          </MobileBg>
        ) : null}
      </Background>
      <ContentContainer>
        <HeroInner>
          <Intro>
            <Title>
              {templateFields.title}
              <TitleLineColored>
                {processText(templateFields.titleBold, activeCity)}
              </TitleLineColored>
            </Title>
            <Subtitle>{templateFields.text}</Subtitle>
            <Cleaner>
              <Picture
                {...convertThumbnailToPictureProps(templateFields.image)}
              />
            </Cleaner>
          </Intro>
          <FormContainer>
            <CalculatorForm submitLabel={templateFields.headerSubmitLabel} />
          </FormContainer>
          <Agreement>
            {t('form.click_and_agree_for', {button_label: t('form.calculator_header_form_label')})}{' '}
            <a href="/privacy-policy" target="_blank">
              {t('form.click_and_agree_for_personal')}
            </a>
          </Agreement>
        </HeroInner>
      </ContentContainer>
      <ScrollDownButton onClick={handleButtonClick}>
        <ScrollDownIcon />
      </ScrollDownButton>
    </HeroContainer>
  );
}

const Agreement = styled.span`
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;

  opacity: 0.75;

  ${media.tablet(css`
    margin-top: 10px;
    color: ${colors.white};
    font-size: 14px;
    line-height: 20px;
  `)}

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const HeroContainer = styled.div`
  position: relative;
  padding: 100px 20px 70px;
  width: 100%;
  min-height: 70vh;
  //z-index: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  ${media.laptop(css`
    padding-top: 80px;
  `)}

  ${media.tablet(css`
    min-height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    // background-color: ${colors.green};
    align-items: normal;
  `)}
  ${media.mobile(css`
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 55px;
  `)};
`;

const Shadow = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  position: absolute;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    position: absolute;
  }
`;
const DesktopBg = styled.div`
  display: block;
  ${media.tablet(css`
    display: none;
  `)}
`;
const MobileBg = styled.div`
  display: none;

  ${media.tablet(css`
    display: block;
  `)}
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 70px;
  ${media.tablet(css`
    margin-top: 30px;
  `)}
  ${media.tabletSmall(css`
    //max-width: 100%;
    padding: 0 20px;
    max-width: 767px;
    margin: 0 auto;
  `)}
  ${media.mobileMedium(css`
    padding: 0 10px;
  `)};
`;

const HeroInner = styled.div`
  position: relative;
  ${media.tablet(css``)}
`;
const Cleaner = styled.div`
  position: absolute;
  top: -95px;
  right: 121px;
  z-index: -1;

  img {
    z-index: -1;
    object-fit: cover;
  }

  ${media.tablet(css`
    display: none;
  `)}
`;

const FormContainer = styled.div`
  padding: 17px 26px;
  background-color: ${colors.white};
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 70px auto 0;
  box-shadow: 0 0 20px 0 #0000003d;
  ${media.tablet(css`
    margin: 0 auto 0;
    max-width: 767px;
    background-color: initial;
    box-shadow: none;
  `)}
`;

const Intro = styled.div`
  position: relative;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;
  ${media.tablet(css`
    padding: 0;
    text-align: center;
  `)}
  ${media.tabletSmall(css`
    margin-left: 26px;
  `)}
`;

const Title = styled.h1`
  margin: 0 0 50px;
  font-size: 79.84px;
  font-weight: 900;
  line-height: 75px;
  color: ${colors.blackText};
  letter-spacing: -1.7px;

  ${media.tablet(css`
    color: ${colors.white};
  `)}
  //
  // ${media.tablet(css`
    //   font-size: 48px;
    //   line-height: 55px;
    //
  `)}
  ${media.tabletSmall(css`
    font-size: 60.32px;
    padding: 0;
    text-align: left;
  `)}
  ${media.mobile(css`
    font-size: 31px;
    text-transform: uppercase;
    line-height: 40px;
    margin-bottom: 15px;
    letter-spacing: 0;
  `)}
  ${media.mobileSmall(css`
    font-size: 25px;
  `)}
`;

const TitleLineColored = styled.span`
  display: block;

  ${media.laptopUp(css`
    color: ${colors.green};
  `)}
`;

const Subtitle = styled.p`
  font-size: 28.8px;
  line-height: 40px;
  color: ${colors.blackText};
  font-weight: 500;
  letter-spacing: -0.5px;
  max-width: 639px;
  ${media.tablet(css`
    max-width: initial;
    //   font-size: 18px;
    //   line-height: 32px;
    color: ${colors.white};
    //
  `)}
  ${media.tabletSmall(css`
    text-align: left;
    font-size: 38.21px;
    font-weight: 600;
  `)}
  ${media.mobile(css`
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 1px;
    color: ${colors.white};
  `)}
  ${media.mobileSmall(css`
    font-size: 15px;
    line-height: 23px;
  `)}
`;

const ScrollDownButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  z-index: 10;
  border-radius: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: ${colors.white};
  ${media.mobile(css`
    display: none;
  `)}
`;

export default Hero;
